import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

import { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';



function Description() {
  
  const { id } = useParams();
  const [research, setResearch] = useState(null);
  
  
  useEffect(() => {
    const fetchResearch = async () => {
      try {
        const response = await axios.get(`https://admin.luilala.com/api/research/${id}/`);
        setResearch(response.data);
        
      } catch (error) {
        console.error('Error fetching the research:', error);
      }
    };

    fetchResearch();
    
  }, [id]);
  if (!research) return <div className="flex justify-center items-center h-96">
          <ClipLoader color={"#000"}  size={50} />
        </div>;
  return (
    <div dir='rtl'  className="bg-[#ffffff] mx-auto px-4 sm:px-6 lg:px-8 py-8 w-fit container">
      <div className="bg-white shadow-xl rounded-lg overflow-hidden">
        <div className="flex flex-col justify-center items-center p-10">
          <div className="md:w-1/2">
            <img
              className="rounded-lg w-full h-64 md:h-auto object-cover"
              src={research.rImage}
              alt=""
            />
          </div>
          <div className="p-6 md:w-1/2">
            <div className="flex items-center mb-2 text-sm">
              <svg
                className="mr-2 w-4 h-4"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                  clipRule="evenodd"
                />
              </svg>
              <span>{research.created_at.substring(0,10)}</span>
            </div>
            <h1 className="mb-4 font-bold text-[#001F3F] text-2xl">{research.rTitle}</h1>

            <div 
      dangerouslySetInnerHTML={{ __html:research.rBody }}
    />


            <p className="mb-6 text-gray-800">{}</p>
           
          </div>
        </div>
      </div>
    </div>
  );
}

export default Description;