import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Home from './pages/Home'
import Videospage from './pages/Videospage'
import About from './pages/About'
import Contact from './pages/Contact'
import Nopage from './pages/Nopage';
import Service from './pages/Service';
import Activities from './pages/Activities';
import Registerf from './pages/register';
import Description from './pages/Description';
import Register2 from './pages/register2';
import Register from './pages/register copy2';
import FileDownloadPage from './pages/FileDownloadPage';
import News from './pages/News';
import DetailsPageActivities from './pages/DetailsPageActivites';


const router=createBrowserRouter([
  {
    path:"/",
    element:<App/>,
    children:[
      {
        path:"/",
        element:<Home/>
      },
      {
        path:"/tafser",
        element:<FileDownloadPage />
      },
      {
        path:"/services",
        element:<Service/>
      },
      {
        path:"/activities",
        element:<Activities/>
      },
      {
        path:"/detailsActivity",
        element:<DetailsPageActivities/>
      },
      {
        path:"/videos",
        element:<Videospage/>
      },
      {
        path:"/about",
        element:<About/>
      },
      {
        path:"/contact",
        element:<Contact/>
      },
      {
        path:"/register",
        element:<Registerf/>
      },
      {
        path:"/copy",
        element:<Register/>
      },
      {
        path:"/register2",
        element:<Register2 />
      },
      {
        path:'/research',
        element:<News/>
      },
      {
        path:'/research/:id',
        element:<Description />
      },
      {
        path:"*",
        element:<Nopage/>
      }
      

    ]
  }
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router}/>
  </React.StrictMode>
);


