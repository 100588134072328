import React from 'react';

import Hero from '../components/Hero';
import Newsletter from '../components/Newsletter';
import { getResearchs } from '../services/getResearch';
import  { useEffect, useState } from 'react';

import cctvImage from "../assets/cctv-security-technology-with-lock-icon-digital-remix_53876-104935.jpg";


import YouTubeEmbed  from '../components/Video'




const Home = () => {

  const [research, setResearch] = useState([]);

  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  

  
    useEffect(() => {
     let mounted = true;
     getResearchs()
       .then(data => {
         if(mounted) {
           setResearch(data);
           setLoading(false);
         }
       })
     return () => mounted = false;
   }, [])




   const currentRecords = research.slice(1, 
    3);



  return (
    
    <div className='bg-white'>
      <Hero />
      <YouTubeEmbed />
      <Newsletter />
      <div dir="rtl" className="flex flex-col items-center gap-4 p-12 cctv">
        <div className="flex md:flex-row flex-col gap-6 sm:gap-8 mt-16 cctv-container">
          <img src={cctvImage} className="shadow-lg rounded-lg w-full md:w-1/2 h-auto sm:h-50 cctv-image object-cover" alt="CCTV Services" />
          <section className="md:text-right flex flex-col md:w-1/2 text-center">
            <h2 className="mb-10 font-semibold text-[#001F3F] text-4xl">خدمات نوین سی سی تی وی</h2>
            <h3 className="text-gray-700 text-lg">خدمات جدیدترین سیستم های سی سی تی وی با کیفیت جهانی اکنون در قندهار قابل دسترس است. برای دریافت این خدمات با شرکت تماس بگیرید</h3>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Home;
